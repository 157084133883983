import {defer} from "lodash";
import {NavigateFunction} from "react-router";

type ScrollFunction = (scroll?: boolean | string) => void;

export function afterNavigate(path: string | undefined, stripQuery: boolean = true, scroll: boolean | string = true,
                              customScroller?: ScrollFunction, navigate?: NavigateFunction) {
    if (navigate && path)
        navigate(path);
    defer(() => {
        if (customScroller) customScroller(scroll);
        else if (scroll === true) window.scrollTo({top: 0, behavior: 'smooth'});
        else if (typeof scroll === 'string') {
            const element = document.getElementById(scroll);
            element?.scrollIntoView({block: "start", behavior: "smooth"});
        }
        if (stripQuery) {
            const {origin, pathname} = window.location;
            window.history.replaceState({}, '', origin + pathname);
        }
    })
}